'use strict';

angular.module('risevision.common.header', [
    'ui.router',
    'risevision.common.gapi',
    'risevision.common.config',
    'risevision.common.header.directives',
    'risevision.core.countries',
    'risevision.store.authorization',
    'risevision.store.services',
    'ui.bootstrap', 'ngSanitize',
    'risevision.common.components.userstate',
    'risevision.common.components.loading',
    'risevision.common.components.scrolling-list',
    'risevision.common.components.logging',
    'risevision.common.support'
  ])

  .factory('bindToScopeWithWatch', [

    function () {
      return function (fnToWatch, scopeVar, scope) {
        scope.$watch(function () {
            return fnToWatch.call();
          },
          function (val) {
            scope[scopeVar] = val;
          });
      };
    }
  ])

  // Fix issue with modal closing when clicking inside the modal, dragging the
  // mouse button and releasing it outside
  // https://github.com/angular-ui/bootstrap/issues/5810#issuecomment-486149448
  .run(['$rootScope', '$document', '$modalStack',
    function ($rootScope, $document, $modalStack) {
      // abort for unit tests
      if ($document[0] && $document[0].querySelectorAll) {
        $rootScope.$watch(function () {
          return $document[0].querySelectorAll('.modal').length;
        }, function (numberOfModals) {
          // Sometimes there are other elements with the .modal class
          var top = $modalStack.getTop();

          if (numberOfModals > 0 && top) {
            $document[0].querySelectorAll('.modal').forEach(function (modal) {
              if (top.value.backdrop !== 'static') { // Don't bother with static modals
                modal.addEventListener('mousedown', function (e) {
                  // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/which
                  var leftButton = 1;
                  if (e.which === leftButton) {
                    var modalContent = modal.querySelector('.modal-content');

                    if (!modalContent.contains(e.target)) {
                      $modalStack.getTop().key.dismiss();
                    }
                  }
                });
              }
            });

            top.value.backdrop = 'static';
          }
        });
      }
    }
  ])

  .run(['analyticsFactory', 'analyticsEvents', 'environment',
    function (analyticsFactory, analyticsEvents, environment) {
      analyticsEvents.initialize();
      analyticsFactory.load(environment.TAG_MANAGER_CONTAINER_ID, environment.TAG_MANAGER_AUTH, environment.TAG_MANAGER_ENV);
    }
  ])

  .run(['$document',
    function ($document) {
      $document.on('keydown', function (event) {
        var doPrevent = false;
        if (event.keyCode === 8) {
          var d = event.srcElement || event.target;
          if ((d.tagName.toUpperCase() === 'INPUT' &&
              (
                d.type.toUpperCase() === 'TEXT' ||
                d.type.toUpperCase() === 'PASSWORD' ||
                d.type.toUpperCase() === 'FILE' ||
                d.type.toUpperCase() === 'SEARCH' ||
                d.type.toUpperCase() === 'EMAIL' ||
                d.type.toUpperCase() === 'NUMBER' ||
                d.type.toUpperCase() === 'DATE' ||
                d.type.toUpperCase() === 'TEL' ||
                d.type.toUpperCase() === 'URL')
            ) ||
            d.tagName.toUpperCase() === 'TEXTAREA') {
            doPrevent = d.readOnly || d.disabled;
          } else {
            doPrevent = true;
          }
        }
        if (doPrevent) {
          event.preventDefault();
        }
      });
    }
  ]);

angular.module('risevision.common.header.directives', []);
angular.module('risevision.store.services', []);
